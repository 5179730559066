import React from "react"
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TourMenu from '../../components/TourMenu';
import { Helmet } from "react-helmet"
import {Link} from 'gatsby';

export default function TestCenter() {

    return (

        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>Feature tour - get started | Skillmeter</title>
        </Helmet>
                
        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
              Feature tour
            </h2>
            <p className="mt-6 text-lg font-normal text-gray-600 ">
            See how easy is to get started with Skillmeter
          </p>               
          </div>
  
          <div className="max-w-5xl mx-auto mt-10 sm:mt-20">
            <div className="flow-root">
            <div className="-my-8 divide-y divide-gray-200">
                <div className="py-8">
                  <div className="flex">
                    <div className="w-1/4">
                        <TourMenu option="7" />

                    </div>
                    <div className="w-3/4">
                      <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl ">
                        Test Center                      
                    </h2>               
                      <br /><br />       
                      <img className="w-full max-w-md" src="https://d2mwjjtum67wgo.cloudfront.net/Content/Public3/img/tour/testcenter.png" alt="" />
                      <br /><br />       

                      <b>Access</b>
                      <br />  
To access the test center, the candidates will go to either https://testcenter.skillmeter.com (standard version) or to your unique Skillmeter sub-domain name (your_company.skillmeter.com) for your branded test center.
<br /><br />       

<b>Multiple language support</b>
<br />  
The test center interface is currently localized in the following languages: English, French, German, Spanish, Dutch, Swedish.
<br /><br />       

<b>Personalization</b>
<br />  
You can personalize your company's test center with your company logo and with links to your company's website and Facebook, Twitter and LinkedIn pages.
<br /><br />       

<b>Results Notifications</b>
<br />  
Skillmeter will send you an email as soon as your candidate completes the test.
<br /><br />       

<b>Anti-Cheating Measures</b>
<br />  
We've implemented anti-cheating measures to restrict candidates copy the questions during the tests, however please note that no measure can be considered 100% efficient (eg: photos can be taken with any mobile device). We always explore and continuously research for new ways of enhancing this area.
<br /><br />       

<b>Test Run</b>
<br />  
The test center interface is very clear and clean to let candidates focus on their assessments. They can see their progress and how much time is left until the end of the test. They can also flag or skip questions to review them at the end of the session.

                      <br /><br />       
                      <img className="w-full max-w-md" src="https://d2mwjjtum67wgo.cloudfront.net/Content/Public3/img/tour/run-test.png" alt="" />

                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>
  
        <Footer />
      </>
    )
}